<!-- @format -->

<template>
  <div class="flex flex-col gap-8">
    <div class="grid grid-cols-1 gap-6">
      <MopfButtonsGroup :buttons="buttons" />
    </div>
    <!-- <div
      class="xl:relative rounded-xl overflow-hidden shadow-lg h-auto border-2 border-gray-100 bg-white"
    >
      <div class="py-4 pl-6 pr-4 flex justify-between">
        <span class="text-black tracking-light text-2xl font-medium">{{
          practiceName
        }}</span>
      </div>
      <div class="border-b-2 border-[#ECECEC] drop-shadow-xl w-full"></div>
      <div class="flex justify-between sm:flex-col md:flex-row">
        <div
          class="text-textGray tracking-light text-md font-medium pt-10 pb-2 pl-8"
        >
          Performance Overview
        </div>
        <div class="flex justify-between gap-4 pt-6 pb-2 pr-8">
          <v-select
            class="sm:w-64 lg:w-36 xl:w-72"
            :options="yearsList"
            buttonWidth="sm:w-52 lg:w-36 xl:w-64"
            placeholder="Select Year"
            @update:modelValue="setYearForAverage"
          />
        </div>
      </div>
      <QOFBarChart
        :key="averageByMonth"
        :data="averageByMonth"
        class="h-full"
      />
    </div>
    <div class="border-2 border-gray-100 rounded-xl">
      <IndicatorsTable
        :indicatorStats="indicatorsWithStats"
        :tableData="indicatorsWithStats.data"
        :tableHeadings="surveyTableHeadings"
        :searchPlaceholder="'Search Indicator'"
        :showPracticeFilter="false"
        @orderBy="indicatorsFilters"
        @searchIndicator="indicatorsFilters"
        @pagination="indicatorsFilters"
      />
    </div> -->
    <div class="border-2 border-gray-100 rounded-xl bg-white">
      <div class="flex flex-row items-center py-1 lg:px-0 sm:px-6 px-6">
        <div class="justify-items-start p-4 text-lg w-1/3">
          <p>{{ practiceSubString }} Action Plans</p>
        </div>
        <div class="flex space-x-4 justify-end p-4 w-2/3 z-10">
          <div
            class="border border-gray-300 bg-white pt-1 rounded leading-tight w-full"
          >
            <DatePicker
              :selectedDate="actionPlanFrameDate"
              @selected-date="actionPlanMeetingFilter"
              @cleared-date="actionPlanMeetingFilter"
              placeholder="Meeting Date"
            />
          </div>
          <div
            class="border border-gray-300 bg-white pt-1 rounded leading-tight w-full"
          >
            <DatePicker
              :selectedDate="actionPlanCreationDate"
              @selected-date="actionPlanCreationFilter"
              @cleared-date="actionPlanCreationFilter"
              placeholder="Creation Date"
            />
          </div>
          <v-select
            class="w-full"
            label="label"
            placeholder="Status"
            :searchable="false"
            :options="statusList"
            @update:modelValue="actionPlanStatus"
          >
          </v-select>
        </div>
      </div>
      <ActionPlanTable
        :key="actionPlansTableData?.data"
        :tableData="actionPlansTableData?.data"
        :tableHeadings="tableHeadings"
        :routeName="actionPlanDetailsRoute"
        :loader="loadingActionPlanTable"
      />
      <Pagination
        v-if="actionPlansTableData?.data?.length > 0"
        :currentPage="actionPlansTableData?.current_page"
        :totalPages="actionPlansTableData?.last_page"
        @page-changed="fetchPage"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

import Pagination from "@/components/newUi/atoms/Pagination.vue";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
import MopfButtonsGroup from "@/components/newUi/baseComponents/MopfButtonsGroup.vue";
// import QOFBarChart from "@/components/newUi/charts/QOFBarChart.vue";
import ActionPlanTable from "@/components/newUi/tables/ActionPlanTable.vue";
// import IndicatorsTable from "@/components/newUi/tables/IndicatorsTable.vue";

const store = useStore();
const loadingActionPlanTable = ref(true);
const practiceName = localStorage.getItem("practice_name");
const practiceSubString = practiceName.substring(0, practiceName.indexOf(" "));
const statusList = ref([
  { label: "In-Progress", value: "in-progress" },
  { label: "Completed", value: "completed" },
]);
const tableHeadings = ref([
  "Agenda",
  "Creation Date",
  "Meeting Count",
  "Meeting Date",
  "Agenda Status",
  "Time Frame",
  "",
]);
// const surveyTableHeadings = ref(["Name", "Percentage", "Points", ""]);
const buttons = ref([
  // {
  //   component: "ma-qof-view-edit",
  //   text: "QOF Data",
  //   subText: "",
  //   icon: "tab",
  //   bgColor: "bg-yellow",
  //   textColor: "text-yellow",
  // },
  {
    component: "ma-mopfCreateActionPlan-qof",
    params: { qof: true },
    text: "Create Action Plan",
    icon: "backup_table",
    bgColor: "bg-aquamarine",
    textColor: "text-aquamarine",
  },
]);
const actionPlanDetailsRoute = ref("ma-actionPlan-detail-qof");
const actionPlanCreationDate = ref(null);
const actionPlanFrameDate = ref(null);
const actionPlanStatusValue = ref(null);

onMounted(async () => {
  fetchAllActionPlans({ tag: "qof", page: 1 });
  // await store.dispatch("maQof/fetchIndicatorsWithStats", {
  //   practiceId: localStorage.getItem("practice"),
  //   page: 1,
  // });
  // await store.dispatch("maQof/fetchAverageByMonth");
});

// const indicatorsWithStats = computed(() => {
//   return store.getters["maQof/getIndicatorsWithStats"];
// });

// const averageByMonth = computed(() => {
//   return store.getters["maQof/getAverageByMonth"];
// });

const fetchAllActionPlans = async (payload) => {
  loadingActionPlanTable.value = true;
  await store
    .dispatch("MAActionPlans/fetchAllActionPlans", payload)
    .then(() => {
      loadingActionPlanTable.value = false;
    });
};

const actionPlansTableData = computed(() => {
  return store.getters["MAActionPlans/getActionPlans"];
});

// const yearsList = computed(() => {
//   var currentYear = new Date().getFullYear(),
//     years = [];
//   var startYear = 2023;
//   while (startYear <= currentYear) {
//     years.push(startYear++);
//   }
//   return years;
// });

// const indicatorsFilters = async (payload) => {
//   const localPayload = ref(payload);
//   localPayload.value.practiceId = localStorage.getItem("practice");
//   await store.dispatch("maQof/fetchIndicatorsWithStats", localPayload.value);
// };

const fetchPage = (page) => {
  if (page > actionPlansTableData.value.last_page) {
    return;
  } else if (actionPlanStatusValue.value || actionPlanFrameDate.value) {
    fetchAllActionPlans({
      tag: "qof",
      status: actionPlanStatusValue.value,
      time_frame: actionPlanFrameDate.value,
      creation_date: actionPlanCreationDate.value,
      page: page,
    });
  } else {
    fetchAllActionPlans({ tag: "qof", page: page });
  }
};

const actionPlanStatus = (status) => {
  if (status != null) {
    actionPlanStatusValue.value = status.value;
  } else {
    fetchAllActionPlans({ tag: "qof", page: 1 });
    return;
  }
  applyActionPlanFilters();
};

const actionPlanMeetingFilter = (selectedDate) => {
  if (selectedDate) {
    actionPlanFrameDate.value = selectedDate;
  } else {
    actionPlanFrameDate.value = null;
  }
  applyActionPlanFilters();
};

const actionPlanCreationFilter = (dateSelected) => {
  if (dateSelected) {
    actionPlanCreationDate.value = dateSelected;
  } else {
    actionPlanCreationDate.value = null;
  }
  applyActionPlanFilters();
};

const applyActionPlanFilters = () => {
  fetchAllActionPlans({
    tag: "qof",
    status: actionPlanStatusValue.value,
    meeting_date: actionPlanFrameDate.value,
    creation_date: actionPlanCreationDate.value,
    page: 1,
  });
};

// const setYearForAverage = async (year) => {
//   if (year) {
//     await store.dispatch("maQof/fetchAverageByMonth", {
//       year: year,
//     });
//   } else {
//     await store.dispatch("maQof/fetchAverageByMonth");
//   }
// };
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 8px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}

::v-deep .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95%;
  overflow: hidden;
}
</style>
